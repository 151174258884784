import { SitesettingService } from './../../service/sitesetting.service';
import { HeaderService } from './../../service/header.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  navtype:any;
  public isMenuCollapsed = true;
 
  constructor( public nav:HeaderService, private route: ActivatedRoute, private router: Router, public sitesetting:SitesettingService) {
      
     
   }

  ngOnInit() { 
    console.log(this.navtype)   
  }

}
