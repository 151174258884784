import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, ActivatedRouteSnapshot } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  visible: boolean = true;
  menutype: string = "website";

  constructor(private route: ActivatedRoute, private router: Router) { 
    
  }

  hide() { this.visible = false; }

  show() { this.visible = true; }

  toggle() { this.visible = !this.visible; }
  
  changemenutype(type) {
      this.menutype = type
  }
  doSomethingElseUseful() { }


}
