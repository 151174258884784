import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SitesettingService {
  Role:any= [{'id':'0', 'role':'user', 'permission':''},{'id':'1', 'role':'Admin', 'permission':''}, {'id':'2', 'role':'Client', 'permission':''}, {'id':'3', 'role':'Developer', 'permission':''}];
  userRole: any = {'id':'', 'role':'', 'permission':''};
  userprofile:any = { "id": "3", "username": "", "lastName": "", "firstName": "", "email": "", "role": "3", "token": "" };
  constructor() { 
    
  } 

  updatedRole(id){
    let roledata = this.Role.filter(el => {      
      return el.id == id;
    })
    this.userRole = roledata[0];
  }
}
