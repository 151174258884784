<nav class="navbar navbar-expand-lg navbar-dark bg-dark" *ngIf="nav.visible" [ngClass]="{'webnav': nav.menutype == 'website' }">
  <div class="container-fluid">
  <a class="navbar-brand" routerLink="/">Web<span>i</span>Beris</a>
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
      <ul class="navbar-nav me-auto" *ngIf="nav.menutype != 'website'">
          <li class="nav-item "><a class="nav-link"  routerLinkActive="active" > {{sitesetting.userprofile['username'] }} :- </a></li>
          <li class="nav-item" *ngIf="sitesetting.userRole.role =='Admin'">
              <a class="nav-link" routerLink="/pms"  (click)="nav.changemenutype('pms')" routerLinkActive="active">PMS </a>
          </li>
          <li class="nav-item ">
              <a class="nav-link" routerLink="/market" routerLinkActive="active" (click)="nav.changemenutype('market')"> Market   </a>
          </li>
          <li class="nav-item d-none" *ngIf="sitesetting.userRole.role =='Admin'">
            <a class="nav-link" routerLink="/sm" routerLinkActive="active" (click)="nav.changemenutype('sm')"> S V Square </a>
        </li>
          
      </ul>
      <ul class="navbar-nav ms-auto" *ngIf="nav.menutype == 'pms' && sitesetting.userRole.role =='Admin'">
          <li class="nav-item">
              <a class="nav-link" routerLink="/pms/dashboard" routerLinkActive="active">Dashboard</a>
            </li>  
            <li class="nav-item">
              <a class="nav-link" routerLink="/pms/docs" routerLinkActive="active">Docs</a>
            </li> 
            <li class="nav-item">
              <a class="nav-link" routerLink="/pms/upwork-job" routerLinkActive="active">Upwork Job</a>
            </li> 
            <li class="nav-item">
              <a class="nav-link" routerLink="/session/registration" routerLinkActive="active">Add User</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/session/login" routerLinkActive="active">Logout</a>
            </li>   
         
        </ul>
        <ul class="navbar-nav ms-auto" *ngIf="nav.menutype == 'sm' && sitesetting.userRole.role =='Admin'">
          <li class="nav-item">
              <a class="nav-link" routerLink="/sm/member" routerLinkActive="active">Member</a>
            </li>  
            <li class="nav-item">
              <a class="nav-link" routerLink="/sm/maintenance" routerLinkActive="active">Maintenance</a>
            </li>
           
            <li class="nav-item">
              <a class="nav-link" routerLink="/session/login" routerLinkActive="active">Logout</a>
            </li>   
         
        </ul>
        <ul class="navbar-nav ms-auto" *ngIf="nav.menutype == 'website'">
          <li class="nav-item">
              <a class="nav-link" routerLink="/website" routerLinkActive="active">Home</a>
            </li>  
            <li class="nav-item">
              <a class="nav-link" >Features</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" >Services</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" >Work</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" >Clients</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" >About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" >Contact</a>
            </li>            
         
        </ul>
    <ul class="navbar-nav ms-auto" *ngIf="nav.menutype == 'market' ">
      <li class="nav-item"> </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/market/home" routerLinkActive="active">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/market/riskratio" routerLinkActive="active">Riskratio</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/market/trading" routerLinkActive="active">Trading</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/market/screener/day" routerLinkActive="active">Screener D</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/market/screener/week" routerLinkActive="active">Screener W</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/market/deals" routerLinkActive="active">Deals</a>
      </li>
      <li class="nav-item">        
        <a class="nav-link" routerLink="/session/login" routerLinkActive="active">Logout</a>
      </li>
    </ul>
  </div>
</div>
</nav>