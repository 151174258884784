import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './session/_helpers/auth.guard';

const routes: Routes = [{
  path:'',
  redirectTo:'pms',
  pathMatch:'full',
},{
  path:"",
  children:[{
    path:'session',
    loadChildren:() => import('./session/session.module').then(m => m.SessionModule),
    data:{nav:""}
  },{
    path:'market',
    loadChildren:() => import('./market/market.module').then(m => m.MarketModule),
    data:{navmarket:true, navtype:'market'},
    canActivate: [AuthGuard]
  },{
    path:'pms',
    loadChildren:() => import('./pms/pms.module').then(m => m.PmsModule),
    data:{navpms:true, navtype:'pms', roles:'1'},
    canActivate: [AuthGuard]
  }
]
}
   
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
   
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
