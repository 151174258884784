import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';
import { HeaderService } from 'src/app/service/header.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public nav:HeaderService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        
        if (currentUser) { 
            let currentuserrole = currentUser.role;
            if(route.data['navtype']){
                this.nav.changemenutype(route.data['navtype']);
            }
            if(route.data['roles']){
                if(route.data['roles'] == currentuserrole){
                    return true;   
                }
            }else{
            
                // authorised so return true
                return true;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/session/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}