import { SitesettingService } from './../../service/sitesetting.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    apiurl :any = 'https://pms.webiberis.com/savedata/';

    constructor(private http: HttpClient, public sitesetting:SitesettingService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        if(this.currentUserSubject.value){
            let userid = this.currentUserSubject.value;
            this.sitesetting.userprofile = this.currentUserSubject.value
            if(userid['role']){
                this.sitesetting.updatedRole(userid['role']);
            }
        }
        return this.currentUserSubject.value;
    }

    login(username, password) {
        let data = {'username':username,'password':password};
        return this.http.post(this.apiurl+'global.php', {'datas':data,'name':'users', 'type':'login'}).pipe(map(data => {
            if(data){
                let datas = data[0];
                if(datas['name']){
                    let user = {
                        id:datas['ID'],
                        username: datas['name'],
                        lastName: datas['lastname'],
                        firstName: datas['firstname'],
                        email: datas['email'],
                        role: datas['role'],
                        token: 'ssss',
                    }
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                    return user;
                }
             } 

        }));         
        
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}